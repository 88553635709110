@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700&display=swap');
html,
body,
#root {
  height: 100%;
  overflow: auto;
}

body {
  font-family: 'Poppins', sans-serif;
}

.ant-layout-header {
  background: #e7e7e7;
  border-bottom: 1px solid rgba(120, 115, 115, 0.51);
}

/*=============================================
=                   Sidebar            =
=============================================*/
.ant-layout-sider {
  background-color: #e7e7e7;
  -webkit-box-shadow: 0px 0px 3.2px 0.8px rgba(120, 115, 115, 0.51);
  box-shadow: 0px 0px 3.2px 0.8px rgba(120, 115, 115, 0.51);
}

.ant-layout-sider .ant-menu {
  background-color: #e7e7e7;
}

.ant-layout-sider .ant-menu-item {
  font-size: 13px;
  font-weight: 500;
}

.ant-layout-sider .ant-menu-item .anticon {
  font-size: 22px;
}

.ant-layout-sider .ant-menu-item a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ant-layout-sider .ant-menu-item.ant-menu-item-selected {
  background-color: #1976d2;
}

.ant-layout-sider .ant-menu-item.ant-menu-item-selected > a {
  color: white;
}

.ant-layout-sider .ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0 13px !important;
}

/*============  End of Sidebar  =============*/
/*=============================================
=                   Table                     =
=============================================*/
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0;
}

.ant-table-small
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th {
  background: #fafafa;
}

.ant-table-small > .ant-table-content > .ant-table-body tr:nth-child(odd) {
  background-color: #f9f9f9;
}

/*============  End of Table  =============*/
.Login {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: white;
}

.Login-logo {
  height: 40vmin;
}

.Login-header {
  font-size: calc(10px + 2vmin);
}

.google-button {
  width: 140px;
  margin: 3px;
}

.ant-menu .ant-menu-item {
  padding-right: 20px !important;
}

.ant-dropdown-menu-item a .anticon {
  margin-right: 5px;
}

.Select-menu-outer {
  z-index: 300;
}
